<template>
	<div class="login row-me">
		<div class="leftBox">
			<div class="texts">
				<img src="../assets/img/title.png">
			</div>
		</div>
		<div class="rightBox">
			<div class="nesText">
				<p>账号登录</p>
				<el-input placeholder="请输入账号"  v-model="mobile" class="loginIpt"
					style="margin-top: 30px;">
				</el-input>
				<el-input placeholder="请输入密码"  v-model="password" class="loginIpt"
					type="password" style="margin-top: 30px;">
				</el-input>
				<el-button class="logins" @click="clickLogin">登录</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		adminLogin
	}
	from "@/api/login/login.js"
	export default {
		components: {

		},
		data() {
			return {
				mobile: "",
				password: "",
				userMobile:""
			}
		},
		// html加载完成之前
		created() {

		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			async clickLogin() {
				if (this.mobile === '' && this.password === '') {
					this.$message.error("账号和密码不能为空！")
					return
				}
				const res = await adminLogin({
					mobile: this.mobile,
					password: this.password,
				})
				this.userMobile=res.data.mobile
				if(res.code==200){
					sessionStorage.setItem('token', res.data.token)
					sessionStorage.setItem('user', JSON.stringify(res.data))
					this.$message.success('登录成功')
					this.$router.replace({
						path: '/index',
					})
				}

			}
		},
		// 计算属性
		computed: {},
		// 侦听器
		watch: {},
	}
</script>

<style lang="scss" scoped>
	html,
	body,
	.login {
		width: 100vw;
		height: 100vh;
		position: relative;

		.leftBox {
			width: 640px;
			height: 100%;
			background-image: url(../assets/img/bg_z.png);
			background-repeat: no-repeat;
		}

		.texts {
			width: 300px;
			height: 116px;
			margin-left: 46px;
			margin-top: 227px;
		}

		.rightBox {
			width: calc(100vw - 640px);
			height: 100%;
			background-color: #F7F9FC;
			background-image: url(../assets/img/bg_y.png);
		}

		.nesText {
			width: 500px;
			height: 620px;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(17, 130, 251, 0.05);
			opacity: 1;
			border-radius: 10px;
			margin: 230px 390px;

			.loginIpt {
				width: 380px;
				height: 50px;
				background-color: #F7F9FC;
				opacity: 1;
				border-radius: 4px;
				margin: 0px 60px;
				outline: none;
				border: none;

			}

			// .el-input__wrapper{
			// 	background-color: #F7F9FC !important;
			// }
		}

		.logins {
			width: 380px;
			height: 50px;
			background: #3D6EE2;
			opacity: 1;
			border-radius: 4px;
			margin-left: 60px;
			margin-top: 70px;
			color: #fff !important;
			font-size: 16px;
		}
	}

	p {
		padding-left: 60px;
		padding-top: 130px;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #252630;
		// letter-spacing: 20px;
		opacity: 1;
	}
</style>
